// dayPart is the block of time we're processing here (like 0 = all day, 1 is morning, 4 is afternoon)

import { when } from 'jquery'

const calendarEntryComposer = (start, dayPart, segmentsReserved) => {
  let startDate = Intl.DateTimeFormat('sv-SE').format(start)
  let domNodes = []
  let dayParts = []
  dayParts[0] = 'Full Day'
  dayParts[1] = 'Full Morning 9a-1p'
  dayParts[4] = 'Full Afternoon 1p-5p'

  // title
  let titleMainFrame = document.createElement('div')
  titleMainFrame.classList = 'fc-event-main-frame modalable'
  let titleContainer = document.createElement('div')
  titleContainer.classList = 'fc-event-title-container'
  let titleElement = document.createElement('div')
  titleElement.innerHTML = dayParts[dayPart]
  titleElement.classList = 'fc-event-title fc-sticky'

  if (
    segmentsReserved.includes(0) ||
    (dayPart == 0 && segmentsReserved.length !== 0) ||
    segmentsReserved.includes(dayPart) ||
    (dayPart == 1 &&
      (segmentsReserved.includes(2) || segmentsReserved.includes(3))) ||
    (dayPart == 4 &&
      (segmentsReserved.includes(5) || segmentsReserved.includes(6)))
  ) {
    titleContainer.classList.add('bg-white')
    titleContainer.classList.add('progress-bar-reserved')
    titleElement.classList.add('calendar-event-title-reserved')
  } else {
    titleContainer.classList.add('bg-burntorange')
    titleMainFrame.dataset.scheduledOn = startDate
    titleMainFrame.dataset.slot = dayPart
    titleMainFrame.dataset.reflex = 'click->Case#modal'
    titleMainFrame.dataset.reflexDataset = 'combined'
  }
  const nodes = [titleMainFrame, titleContainer, titleElement]
  for (var i = 1; i < nodes.length; i++) {
    nodes[i - 1].appendChild(nodes[i])
  }
  domNodes.push(titleMainFrame)

  // morning or afternoon, because only they have sub-parts
  if (dayPart != 0) {
    let hourSegments = []
    hourSegments[1] = []
    hourSegments[1][2] = '8a-10a'
    hourSegments[1][3] = '10a-12p'
    hourSegments[4] = []
    hourSegments[4][5] = '1p-3p'
    hourSegments[4][6] = '3p-5p'

    let progressContainer = document.createElement('div')
    progressContainer.classList = 'progress'
    hourSegments[dayPart].forEach(function (element, index) {
      let progressBar = document.createElement('div')
      progressBar.innerHTML = element

      // For Debugging Only
      // if (segmentsReserved.length != 0) {
      //   console.log('segmentsReserved: ', segmentsReserved)
      //   console.log('index: ', index)
      //   console.log('element: ', element)
      //   console.log('does sR include 0: ', segmentsReserved.includes(0))
      //   console.log('is index in sR: ', segmentsReserved.includes(index))
      //   console.log('dayPart: ', dayPart)
      //   console.log(
      //     'does sR include dayPart: ',
      //     segmentsReserved.includes(dayPart)
      //   )
      // }

      if (
        segmentsReserved.includes(0) ||
        segmentsReserved.includes(index) ||
        segmentsReserved.includes(dayPart)
      ) {
        progressBar.classList =
          'progress-bar progress-bar-striped bg-white progress-bar-reserved'
      } else {
        progressBar.classList = 'progress-bar modalable bg-burntorange'
        progressBar.dataset.scheduledOn = startDate
        progressBar.dataset.slot = index
        progressBar.dataset.reflex = 'click->Case#modal'
        progressBar.dataset.reflexDataset = 'combined'
      }
      progressContainer.appendChild(progressBar)
    })

    domNodes.push(progressContainer)
  }
  return domNodes
}

export default calendarEntryComposer
