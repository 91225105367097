import ApplicationController from './application_controller'
export default class extends ApplicationController {
  connect() {
    super.connect()
  }

  static targets = ['modal', 'phone', 'fax', 'links', 'template', 'limbo']

  initialize() {
    Inputmask({ mask: '(999) 999-9999' }).mask(this.phoneTargets)
    Inputmask({ mask: '(999) 999-9999' }).mask(this.faxTargets)
  }

  new() {
    // console.log('new!')
  }

  add_lawyer(event) {
    event.preventDefault()
    const content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime()
    )
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_lawyer(event) {
    event.preventDefault()
    let wrapper = event.target.closest('.nested-fields')
    if (wrapper.dataset.newRecord == 'true') {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
  }

  show_modal(event) {
    this.stimulate('Case#set_case_options', event)
    // console.log('show modal!')
  }

  launch_form(event) {
    event.preventDefault()
    // console.log('hitting launch_form!')
    // console.log(event.target.dataset.slot)
  }

  afterModal(element, reflex, noop, reflexId) {
    $('#caseModal').modal('show')
  }

  toggle_limbo(event) {
    let formData = new FormData()
    if (this.limboTarget.checked) {
      formData.append('case[status]', 'limbo')
    } else {
      formData.append('case[status]', 'pending')
    }
    const csrfToken = document.head
      .querySelector(`meta[name="csrf-token"]`)
      .getAttribute('content')

    fetch(this.data.get('update-url'), {
      body: formData,
      method: 'PATCH',
      credentials: 'include',
      dataType: 'script',
      headers: {
        'X-CSRF-Token': csrfToken,
      },
    }).then(function (response) {
      if (response.status != 204) {
        event.target.checked = !event.target.checked
      }
    })

    // page treatment to let the admin know this is in limbo now
  }
}
